import '~/src/datadog/init-browser-logs'
import { ApolloProvider } from '@apollo/client'
import { CacheProvider, type EmotionCache } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { type CustomTheme, ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useCookie } from 'next-cookie'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { type ReactElement, Suspense, useCallback } from 'react'
import { AUTH_REDIRECT_URL, ROUTES } from '~/src/constants'
import { DialogProvider } from '~/src/context/dialog/dialog-context'
import { IntrospectionDetailProvider } from '~/src/context/introspection'
import { PermissionProvider } from '~/src/context/permissions/permissions'
import { useAppController } from '~/src/hooks/app/app-controller'
import NotificationsProvider from '~/src/hooks/notifications'
import { useAuthRedirect } from '~/src/hooks/session/use-auth-redirect'
import { useApollo } from '~/src/lib/apollo/apollo-client'
import createEmotionCache from '~/src/lib/emotion/create-emotion-cache'
import { yupInitialize } from '~/src/lib/yup'
import { theme } from '~/src/theme/create-theme'
import { getLocalImagePath } from '~/src/utils/images/get-local-image-path'
import nextI18NextConfig from '../next-i18next.config.js'

/**
 * Required for standalone mode: nextjs excludes from server bundle by default
 */
if (typeof window === 'undefined') {
  require('dd-trace')
}

import './_app.css'

yupInitialize()

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

function CustomApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: MyAppProps): ReactElement {
  const controller = useAppController()
  const apolloClient = useApollo({
    initialState: pageProps.initialApolloState,
    errorHandlers: controller.getErrorHandlers(),
  })

  useAuthRedirect({
    redirect: pageProps.redirect || controller.redirect,
    postRedirect: useCallback(() => {
      controller.clearRedirect()
    }, [controller]),
  })

  const router = useRouter()
  const cookie = useCookie()

  if (pageProps.redirect) {
    cookie.set(AUTH_REDIRECT_URL, {
      pathname: router.pathname,
      query: router.query,
    })
  }

  return (
    <>
      <Head>
        <link rel="icon" href={getLocalImagePath('/favicon.svg')} />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>
      <CacheProvider value={emotionCache}>
        <ThemeProvider<CustomTheme> theme={theme}>
          <CssBaseline />
          <ApolloProvider client={apolloClient}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <PermissionProvider>
                <NotificationsProvider>
                  <DialogProvider>
                    {pageProps.redirect ? (
                      <div>
                        Not authenticated. Redirecting to login... <br />
                        If this doesn&apos;t happen automatically, please go to{' '}
                        <NextLink href={ROUTES.login}>login</NextLink>
                      </div>
                    ) : (
                      <Suspense fallback={<div />}>
                        <IntrospectionDetailProvider endpoints={pageProps.introspectionEndpoints}>
                          <Component {...pageProps} />
                        </IntrospectionDetailProvider>
                      </Suspense>
                    )}
                  </DialogProvider>
                </NotificationsProvider>
              </PermissionProvider>
            </LocalizationProvider>
          </ApolloProvider>
        </ThemeProvider>
      </CacheProvider>
    </>
  )
}

export default appWithTranslation(CustomApp, nextI18NextConfig)
