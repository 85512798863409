import { Avatar as AvatarMUI } from '@mui/material'
import type { OverridableTypeMap, OverrideProps } from '@mui/material/OverridableComponent'
import { map } from 'fp-ts/lib/Array'
import { flow } from 'fp-ts/lib/function'
import * as R from 'ramda'

import type { FC, ReactElement } from 'react'

type MaterialComponentLike = OverrideProps<OverridableTypeMap, React.ElementType>
export type Props = MaterialComponentLike & {
  src?: string
  userName: string
}

const getFirstLetters = flow(R.split(/\s+/g), map<string, string>(R.slice(0, 1)), R.join(''))
const getUserNameAvatarFormatted = flow(R.when(R.test(/\s/g), getFirstLetters), (str) =>
  str.slice(0, 2),
)

const Avatar: FC<Props> = ({ src, userName, ...props }): ReactElement => {
  return (
    <AvatarMUI {...props} alt={userName} src={src}>
      {getUserNameAvatarFormatted(userName)}
    </AvatarMUI>
  )
}

export default Avatar
