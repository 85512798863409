export const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const weekDaysShort = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']

export const weekMinutes = 60 * 24 * 7

export const dayPercent = 100 / weekdays.length
export const secondsInDay = 60 * 60 * 24
export const secondsInWeek = secondsInDay * weekdays.length
export const secondsPercent = secondsInWeek / weekdays.length
