export const ColorStrings = {
  PrimaryMain: 'primary.main',
  PrimaryDark: 'primary.dark',
  SecondaryMain: 'secondary.main',
  SecondaryDark: 'secondary.dark',
  WarningMain: 'warning.main',
  ErrorMain: 'error.main',
  ErrorDark: 'error.dark',
  TextDeepGray: 'text.deepGray',
  TextLightBlack: 'text.lightBlack',
  TextPrimary: 'text.primary',
  TextTopMenuSecondary: 'text.topMenuSecondary',
  TextTopMenuActive: 'text.topMenuActive',
  BodyContent: 'background.bodyContent',
  WarningDark: 'warning.dark',
  TextDisabled: 'text.disabled',
}

export const SchedulerColors = [
  ColorStrings.PrimaryMain,
  ColorStrings.PrimaryDark,
  ColorStrings.SecondaryMain,
  ColorStrings.SecondaryDark,
  ColorStrings.WarningMain,
  ColorStrings.WarningDark,
  ColorStrings.ErrorMain,
  ColorStrings.ErrorDark,
  ColorStrings.TextDeepGray,
]

export const ContinuousIntervalScheduleColor = '#D15982'
export const PrimaryIntervalColor = '#007fff'

export const ExtendedSchedulerLighterColors = [
  // Contrast: white color
  '#7f0000', // Darker Red
  '#7f3f00', // Darker Orange
  '#7f7f00', // Darker Yellow
  '#3f7f00', // Darker Lime Green
  '#007f00', // Darker Green
  '#007f3f', // Darker Cyan
  '#007f7f', // Darker Light Blue
  '#003f7f', // Darker Blue
  '#00007f', // Darker Indigo
  '#3f007f', // Darker Purple
  '#7f007f', // Darker Pink
  '#7f003f', // Darker Magenta
  PrimaryIntervalColor, // Lighter Blue
  '#0000ff', // Lighter Indigo
  '#7f00ff', // Lighter Purple
]

export const ExtendedSchedulerDarkerColors = [
  // Contrast: black color
  '#ff0000', // Lighter Red
  '#ff7f00', // Lighter Orange
  '#ffff00', // Lighter Yellow
  '#7fff00', // Lighter Lime Green
  '#00ff00', // Lighter Green
  '#00ff7f', // Lighter Cyan
  '#ff00ff', // Lighter Pink
  '#ff007f', // Lighter Magenta
  '#00ffff', // Lighter Light Blue
]

export const ExtendedSchedulerColors = [
  ...ExtendedSchedulerLighterColors,
  ...ExtendedSchedulerDarkerColors,
]
