enum ROUTES {
  home = '/',
  companies = '/companies',
  products = '/products',
  payments = '/payments',
  users = '/users',
  jackpots = '/jackpots',
  templates = '/templates',
  systems = '/systems',
  teams = '/teams',
  locations = '/locations',
  games = '/games',
  login = '/login',
  levels = '/levels',
  analytics = '/analytics',
  logs = '/logs',
  hits = '/hits-approvals',
  register = '/register',
  resetPassword = '/reset-password',
  integrants = '/integrants',
  notFound = '/404',
  serviceInvoices = '/service-invoices',
  configuration = '/configuration',
  subscriptionPlans = '/subscription-plans',
}

export const SUB_ROUTES = {
  SecurityCode2fa: `${ROUTES.login}/2fa/security-code`,
  Download2fa: `${ROUTES.login}/2fa/download`,
  Setup2fa: `${ROUTES.login}/2fa/setup`,
  RestorePassword: `${ROUTES.login}/restore-password`,
}

export default ROUTES
