import { List } from '@mui/material'
import { type CustomTheme, useTheme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React, { type FC } from 'react'
import { getSubMenuConfig } from '~/src/config/sub-menu'
import type { MenuCategories } from '~/src/config/top-menu'
import { GTM_ATTRIBUTE, GTM_EVENT } from '~/src/constants'
import { dataGtmEvent } from '~/src/helpers/gtm/data-event'
import { useAccessPermissionFn } from '~/src/hooks/permissions/use-access-permission-fn'
import {
  StyledListItemText,
  StyledPrimaryListItemButton,
  listItemTextClasses,
  primaryListItemClasses,
} from './styles'

type Props = {
  category: MenuCategories
}
export const compareHrefWithPathname = (pathname: string, href: string): boolean => {
  const splittedHref = href.split('/')
  const splittedPathname = pathname.split('/').slice(0, splittedHref.length)
  return splittedHref.every((part, index) => part === splittedPathname[index])
}

const SubMenu: FC<Props> = ({ category }) => {
  const { t } = useTranslation()
  const theme = useTheme<CustomTheme>()
  const { pathname } = useRouter()
  const subMenu = getSubMenuConfig()
  const categorySubMenu = subMenu[category]
  const isAllowedPermission = useAccessPermissionFn()

  if (!categorySubMenu) {
    return <div>Menu unavailable</div>
  }

  return (
    <List
      component="nav"
      sx={{
        paddingTop: 0,
        paddingRight: theme.spacing(theme.constants.leftMenu.paddingRight),
      }}
      dense
      aria-labelledby="nested-list-subheader"
    >
      {categorySubMenu?.items?.map(({ id, translateKey, href, accessPermission }) => {
        if (!isAllowedPermission(accessPermission)) {
          return null
        }

        return (
          <React.Fragment key={id}>
            <NextLink href={href} passHref legacyBehavior>
              <StyledPrimaryListItemButton
                component="a"
                classes={primaryListItemClasses}
                selected={compareHrefWithPathname(pathname, href)}
                data-cy={`subMenu:${id}`}
                {...dataGtmEvent(GTM_EVENT.SUB_MENU_CLICK, {
                  [GTM_ATTRIBUTE.GTM_ITEM_ID]: id,
                })}
              >
                <StyledListItemText primary={t(translateKey)} classes={listItemTextClasses} />
              </StyledPrimaryListItemButton>
            </NextLink>
          </React.Fragment>
        )
      })}
    </List>
  )
}

export default SubMenu
