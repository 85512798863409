export { default as ROUTES } from './routes'
export * from './operate-mode'
export * from './auth'
export * from './form'
export * from './datadog'
export * from './local-storage'
export * from './autoupdate'
export * from './entity'
export * from './gtm'
export * from './filter-debounce-timeout'
export * from './table'
export * from './table-ids'
export * from './date'

export const BasePath = process.env.NEXT_PUBLIC_PATH_PREFIX ?? ''
export const JSAMainUserName = 'jsa'
export const JSAMainUserNameNew = 'Jackpot aggregator'

export const isLevelSchedulerEnabled = () =>
  process.env.NEXT_PUBLIC_LEVEL_SCHEDULER_ENABLED === 'true'
