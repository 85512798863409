import type { SessionRichUser } from '~/src/hooks/session/use-load-user'
import type { AccessPermissionPossibleTypes } from '~/src/types/menu'

export type MenuConfigItemTopMenu = {
  id: MenuCategories
  translateKey: string
  href: string
  accessPermission?: AccessPermissionPossibleTypes
}

export type MenuConfigTopMenu = {
  items: MenuConfigItemTopMenu[]
}

export enum MenuCategories {
  enterprise = 'enterprise',
  jackpots = 'jackpots',
  help = 'help',
  visualisation = 'visualisation',
}

const topMenuConfig = {
  items: [
    {
      id: MenuCategories.enterprise,
      translateKey: 'header:enterprise',
      href: '/companies',
    },
    {
      id: MenuCategories.jackpots,
      translateKey: 'header:jackpots',
      href: '/jackpots',
    },
    {
      id: MenuCategories.help,
      translateKey: 'header:help',
      href: '/about',
    },
    {
      id: MenuCategories.visualisation,
      translateKey: 'header:visualisation',
      href: '/configuration',
      accessPermission: (user: SessionRichUser) => user.isJA,
    },
  ],
}

export const getMenuConfig = (): MenuConfigTopMenu => topMenuConfig
